import { LETTERS_M } from "/src/js/CONSTs"
/*  */
export class Decipher {
    constructor({
        target,
        detector = null,
        detectorType = null,
        auto = false,
        autoCooldown = 0,
        cooldown = 0,
        attempts = 3,
        words,
    }) {
        this.target = target
        this.detector = detector

        this.auto = auto
        this.autoCooldown = autoCooldown

        this.cooldown = cooldown
        this.attempts = attempts

        this.words = []
        for (const [i, word] of words.entries()) {
            this.words[i] = word.split("")
        }

        this.wordIndex = 0

        this.addListener(detectorType)
    }
    addListener(type) {
        if (this.auto) {
            this.trigger()
        } else {
            this.detector.addEventListener(type, () => this.trigger())
        }
    }
    trigger() {
        let iteration = 0

        let attempt = 0

        let test = []

        let interval = setInterval(() => {
            // Get random letter
            let letter = LETTERS_M[Math.floor(Math.random() * 26)]
            // Modify innerText
            this.target.innerText = test.join("") + letter
            // Add attempt
            attempt++
            // Check attempt
            if (attempt == this.attempts) {
                test.push(this.words[this.wordIndex][iteration])
                attempt = 0
                iteration++
                this.target.innerText = test.join("")
            }
            // Check if the word is finished
            if (iteration >= this.words[this.wordIndex].length) {
                clearInterval(interval)
                this.wordIndex++
                if (this.wordIndex >= this.words.length) this.wordIndex = 0
                // Restart if auto
                if (this.auto) {
                    let autoInterval = setInterval(() => {
                        this.trigger()
                        clearInterval(autoInterval)
                    }, this.autoCooldown)
                }
            }
        }, this.cooldown)
    }
}
